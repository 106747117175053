.is-clickable {
  cursor: pointer;
}

.bookbox {
width: fit-content;
position: relative;
margin: 0px 10px 25px 10px;
}

.button {
border-width: 3px;
}

img {
padding-bottom: 0;
height: 200px;
min-width: fit-content;
margin:0;
box-shadow: 16px -5px 13px -2px rgba(56,48,40,1);
}

@media screen and (max-width: 600px) {
.bookbox, img {
  width: 80%;
  height: auto;
  margin-bottom: 50px;
  margin-top: 50px;

}
}