.bookrow {
  margin-top: 15px;
  overflow-x: auto;
}

.bookrow::-webkit-scrollbar {
  color: transparent;
}

@media screen and (max-width: 600px) {
  .bookrow::-webkit-scrollbar-thumb {
    display: none;
  }
}

.bookrow::-webkit-scrollbar-thumb {
  background-color: #48C774;
  border-radius: 20%;
}
.bookrow::-webkit-scrollbar-thumb:hover {
  background-color: #36B161;
}

.borrowrow {
  margin-top: 30px;
  overflow-x: auto;

}

.borrowrow::-webkit-scrollbar {
  color: transparent;
}

.borrowrow::-webkit-scrollbar-thumb {
  background-color: #CB7E55;
  border-radius: 20%;
}

@media screen and (max-width: 600px) {
  .borrowrow::-webkit-scrollbar-thumb {
    display: none;
  }
}

.borrowrow::-webkit-scrollbar-thumb:hover {
  background-color: #C06A3B;
}


.hero {
  border-radius: 10px;
  margin-top: -10px;
  padding-top: 0;
}

.hero-foot {
  margin-bottom: 20px;
}

.findbtn {
  background-color: #CB7E55;
  color: white;
  border:none
}

.bookContainer {
  margin-left: 10px;
  margin-right: 10px;
  z-index: 3;
}

.bookcover {
  position: relative;
  padding: 0;
  margin: 0;
}

html,
body {
  background-image: url('https://i.ibb.co/rkNWrgL/bookcase-bg.jpg');
  height: 100%;
  background-size: cover;
}

.hoverpic {
  opacity: 1;
  display: block;
  transition: 0.4s ease;
  backface-visibility: hidden;
}

.polygon {
  clip-path: polygon(5% 8%, 3% 28%, 3% 71%, 97% 72%, 97% 28%, 95% 8%);
  background: linear-gradient(to bottom, #b99c86, #753d13 32% 100%);
  width: auto;
  height: 80px;
  z-index: 1;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .polygon {
    visibility: hidden;
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .bookContainer, img {
    width: 100%;
    height: auto;

  }
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.bookcover:hover .middle {
  opacity: 1;
}

.box {
  height: 200px;
  background-color: transparent;
  min-width: fit-content;
}

.accountSection {
  margin-top: 75px;
}
