.navbar-link {
  color: #f5f5f5;
}

.label.is-large {
  font-size: 2rem;
}

.navbar-item {
  color: #f5f5f5;
}
