.card-image {
border: 1px solid #ddd;
border-radius: 5px;
padding:5px;
width: 300px;
height:450px;
};

.modal {
  z-index: 10;
}

.modal-background {
  color: whitesmoke;
}

.image {
  justify-content: center;
}

.book-cover {
  margin: 0px auto;
}

/* p {
color: black
} */