body {
  background-image: "../../../public/bookcase_bg.png";
}

.books {
  background-color: whitesmoke;
  margin: 25px;
  border-radius: 10px;
  color: black;
}

.search {
  margin-top: 100px;
}

.addtolibrary {
  margin-top: 20px;
}

.bookcoveradd {
  margin-top: 20px;
}
